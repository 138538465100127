const CryptoTermsAndConditions = () => {

  return (
    <div className="p-4 crypto-t-and-c" style={{ backgroundColor: 'white', }}>
      <div className="text-center mb-4">
        <img width="100" height="100" src="/assets/images/niuHealthLogo.svg" class="attachment-2048x2048 size-2048x2048 wp-image-757" alt="Niu Health logo" sizes="(max-width: 588px) 100vw, 588px"></img>
      </div>
      <h1 className="mb-4 text-center" style={{ color: 'black' }}>NIU Crypto Coin (NIUtopia) Terms and Conditions</h1>
      <div className="mx-5">
        <div>
          <h3 className="font-weight-bold">1. Introduction</h3>
          <p>
            These Terms and Conditions govern the use, ownership, and transactions involving the NIU
            Health Coin (NIUtopia), a blockchain-based cryptocurrency developed by NIU Health. By using
            NIUtopia, you agree to these terms.
          </p>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">2. Definitions</h3>
          <ul>
            <li><strong>NIUtopia:</strong> The digital token issued by NIU Health as part of its blockchain-based healthcare platform.</li>
            <li><strong>Platform:</strong> The NIU Health ecosystem, including applications, services, and functionalities.</li>
            <li><strong>User:</strong> Any individual or entity that uses, holds, or transacts NIUtopia tokens.</li>
          </ul>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">3. Eligibility</h3>
          <ul>
            <li>Be at least 18 years old.</li>
            <li>Comply with all applicable laws and regulations, including those related to cryptocurrency use in their jurisdiction.</li>
            <li>Not be a resident of any jurisdiction where cryptocurrency is prohibited or restricted.</li>
          </ul>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">4. NIUtopia Usage</h3>
          <ul>
            <li><strong>Earning:</strong> Users can earn NIUtopia through platform activities, including participating in healthcare programs, completing tasks, and engaging with educational content.</li>
            <li><strong>Spending:</strong> NIUtopia may be used for healthcare services, telehealth consultations, purchasing wellness products, and accessing platform features.</li>
            <li><strong>Trading:</strong> NIUtopia may be traded on approved cryptocurrency exchanges, subject to applicable laws.</li>
          </ul>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">5. Tokenomics</h3>
          <ul>
            <li><strong>Total Supply:</strong> 1 billion NIUtopia tokens.</li>
            <li><strong>Consensus Mechanism:</strong> Proof of Work (PoW) for mining and validation of transactions.</li>
            <li><strong>Rewards:</strong> Mining rewards and engagement incentives are subject to change as per platform policies.</li>
          </ul>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">6. Ownership and Rights</h3>
          <p>
            Ownership of NIUtopia does not confer any ownership or rights over NIU Health or its operations. NIUtopia holders are not entitled to dividends, voting rights, or equity in NIU Health.
          </p>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">7. Risks and Disclaimers</h3>
          <ul>
            <li>Cryptocurrency values are volatile. NIUtopia value may increase or decrease significantly.</li>
            <li>NIU Health does not guarantee liquidity, future value, or the availability of exchanges.</li>
            <li>Users accept the inherent risks associated with cryptocurrency transactions, including cybersecurity threats and technical failures.</li>
          </ul>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold"> 8. Restrictions</h3>
          <ul>
            <li>NIUtopia cannot be used for illegal activities or in violation of any local, national, or international laws.</li>
            <li>NIUtopia may not be used for speculative purposes or leveraged against NIU Health’s reputation.</li>
          </ul>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">9. Privacy and Data Protection</h3>
          <p>
            Users agree to NIU Health’s Privacy Policy for the collection, use, and storage of personal information in connection with NIUtopia transactions.
          </p>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">10. Termination of Services</h3>
          <p>
            NIU Health reserves the right to suspend or terminate the use of NIUtopia for any user found in violation of these terms.
          </p>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">11. Modification of Terms</h3>
          <p>
            NIU Health may modify these terms at any time. Users will be notified of significant changes via the platform, and continued use of NIUtopia constitutes acceptance of the revised terms.
          </p>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">12. Governing Law</h3>
          <p>
            These terms shall be governed by the laws of [jurisdiction], without regard to its conflict of laws principles.
          </p>
        </div>

        <hr />

        <div>
          <h3 className="font-weight-bold">13. Contact Information</h3>
          <p>
            For questions or concerns regarding NIUtopia, users can contact NIU Health at <a href="tel:(808) 888 4800">(808) 888 4800</a> or <a href="mailto:hello@niuhealth.com">hello@niuhealth.com</a>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CryptoTermsAndConditions;

import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { usePlacesWidget } from "react-google-autocomplete";
import moment from "moment";
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import i18n from 'v2/src/lib/i18n';

import "./icon.css"
import {
  DEF_COUNTRY_ID,
  DEF_COUNTRY_CODE,
  DEF_COUNTRY_NAME,
  DEF_LANGUAGE_CODE,
  DEF_PROFILE_PIC_URL,
  GOOGLE_PLACES_API_KEY,
  USER_TYPES,
  FLG_USE_SWAL,
} from "v2/src/config/appConfig";
import {
  fGetAUserInf,
  fGetLocalSt,
  fParseGglPlaces,
  fFormatGglAddressComps,
} from "v2/src/lib/appUtils";
import { fStableSort, fGetComparator } from "v2/src/lib/objUtils";
import { fCallNHApi } from "./appProfileFx";
import {
  PageMessage,
  Flasher,
  LanguageDropdown,
  LottieSpinner,
} from "v2/src/core";
import ProfilePicSetter from "v2/src/core/UploadImage/ProfilePicSetter";
import TimeZoneSelect2 from "v2/src/core/TimezoneDrodown/Timezone2";
import ProfileFormSchema from "v2/src/schema/profileFormSchema";
import { rSetProfilePic } from "v2/src/lib/services/auth/authSlice";
import { useSetNSUserMutation } from "v2/src/lib/services/nSUtilApi";
import {
  useLocationCompQuery,
  useAddProfileAddressMutation,
  useSetAddressMutation,
  useLanguagesQuery,
} from "v2/src/lib/services/locationApi";
import {
  useUserProfileDetailQuery,
  useLazyUserDetailsQuery,
  useSetUserMutation,
  useSetUserProfileMutation,
} from "v2/src/lib/services/profileApi";
import { updatedefaultLocalLang } from "v2/src/lib/services/auth/langSlice";
import { useCreateWalletMutation } from "v2/src/lib/services/nHWalletApi";

export default function ProfileForm() {
  const fDispatch = useDispatch();
  let aUData = fGetAUserInf();
  const dobRef = useRef();

  const [createWallet] = useCreateWalletMutation();

  const { t: error_message } = useTranslation("error_message");
  const { t: comp_profile } = useTranslation("comp_profile");
  const { t: common } = useTranslation('common');
  const [stPgMsg, setStPgMsg] = useState("");
  const [stProcessing, setStProcessing] = useState(false);
  const [stPgHasErr, setStPgHasErr] = useState(false);

  const flgUseTimezoneScheme2 = true;
  const countryCookieName = "niuHCId";
  const selCountryId = fGetLocalSt(countryCookieName) || DEF_COUNTRY_ID;
  const defCountryId = DEF_COUNTRY_ID;
  const defCountryCode = DEF_COUNTRY_CODE === "USA" ? "US" : DEF_COUNTRY_CODE;
  const defCountryName = DEF_COUNTRY_NAME;
  const aUserId = aUData?.userIdNH;
  const aUserType = aUData?.userType;
  const aUserNSId = aUData?.userIdNS;
  const aUserProfileId = aUData?.details?.profileId;
  const aUserLanguageCode = DEF_LANGUAGE_CODE;
  const dummyDob = "1920-01-01";
  const elDatePickerId = "DateOfBirth";
  let aUserDob, aUserAddressData, aUserAddressId;

  const [selectedDate, setSelectedDate] = useState(null);
  const isMounted = useRef(true);
  const [fSetUserData] = useSetUserMutation();
  const [fSetUserProfile] = useSetUserProfileMutation();
  const [fAddUserAddr] = useAddProfileAddressMutation();
  const [fSetUserAddr] = useSetAddressMutation();
  const [fSetNSUser] = useSetNSUserMutation();
  const [localLangId, setLocalLangId] = useState('1');
  const [localLang, setLocalLang] = useState({});
  const defaultLocalLang = useSelector(store => store.lang?.defaultLocalLang)
  const [fGetUserProfDetails] = useLazyUserDetailsQuery({
    profileId: null,
    userType: null,
  });

  let arrLangs = [],
    arrStates = [],
    arrTz = [];
  const {
    data: locApiData,
    isLoading: locWait,
    isFetching: locWait2,
  } = useLocationCompQuery(
    { countryId: selCountryId },
    { skip: selCountryId === null }
  );

  if (locApiData) {
    arrLangs = locApiData?.languages?.data || [];
    arrStates = locApiData?.states?.data || [];
    arrStates = fStableSort(arrStates, fGetComparator("name", "asc"));
    arrTz = locApiData?.timezones?.data || [];
  }

  const arrComps = ["user", "role", "language", "addresses", "patient"];

  const shouldSkip = aUserProfileId === null;

  const {
    data: profileApiData,
    refetch: fRefetchProfile,
    isLoading: profWait,
    isFetching: profWait2,
    isError: profErr,
  } = useUserProfileDetailQuery(
    { profileId: aUserProfileId, arrComps },
    { skip: shouldSkip, refetchOnMountOrArgChange: true }
  );
  const objProfData = profileApiData?.data;
  aUserDob = objProfData?.date_of_birth;

  if (objProfData?.addresses?.length) {
    aUserAddressData = objProfData?.addresses.find(
      (rAddr) => rAddr?.is_primary === 1
    );

    aUserAddressId = aUserAddressData?.id;
  }

  const fHandleLanguageChange = (selOpt) => {
    setLocalLangId(selOpt.id);
    setLocalLang(selOpt);
    fSetFrmProfileVal("Language", selOpt.value);
  };

  useEffect(() => {
    objProfData && setSelectedDate(objProfData?.date_of_birth)
  }, [objProfData])

  objProfData &&
    console.log("objProfData_" + profErr, {
      aUDataPF: aUData,
      objProfData,
      aUserAddressData,
    });
  useEffect(() => {
    if (profErr) {
      setStPgMsg("Error fetching user profile data");

      setStPgHasErr(true);
    }
  }, [profErr]);

  const fResetProcessing = (flgClearPgMsg = false) => {
    setStProcessing(false);
    setStPgMsg("");
    setStPgHasErr(false);
  };

  const {
    register: fRegFrmProfile,
    reset: fResetFrmProfile,
    trigger: fTriggerFrmProfile,
    setValue: fSetFrmProfileVal,
    getValues: fGetFrmProfileVal,
    setError,
    clearErrors,
    formState: { errors: errFrmProfile, isValid: isValidFrmProfile },
  } = useForm({
    resolver: yupResolver(ProfileFormSchema(error_message)),
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const obPData = profileApiData?.data;

    if (obPData) {
      fSetFrmProfileVal("FirstName", obPData?.user?.first_name || "");
      fSetFrmProfileVal("LastName", obPData?.user?.last_name || "");
      fSetFrmProfileVal("contactNum", obPData?.user?.phone || "");
      fSetFrmProfileVal("Address1", aUserAddressData?.address_line_1 || "");
      fSetFrmProfileVal("Address2", aUserAddressData?.address_line_2 || "");
      fSetFrmProfileVal("City", aUserAddressData?.city || "");
      fSetFrmProfileVal("Province", aUserAddressData?.state?.id || "");
      fSetFrmProfileVal("ZIPCode", aUserAddressData?.zipcode || "");
      fSetFrmProfileVal("LocalTimeZone", obPData?.timezone_id || 0);
      fSetFrmProfileVal("CountryID", defCountryId);
      fSetFrmProfileVal(
        "DateOfBirth",
        obPData?.date_of_birth !== dummyDob ? obPData?.date_of_birth : ""
      );
    }
    else {
      if (!shouldSkip && isMounted.current) {
        fRefetchProfile();
      }
    }
  }, [profileApiData, locApiData, fSetFrmProfileVal]);


  const fAutoPopulateAddress = async (tEl, plInf, langCode = null) => {
    let addrComps, tzInf, cTZs;
    console.log("Pl", plInf);

    addrComps = fParseGglPlaces(plInf, GOOGLE_PLACES_API_KEY, langCode);
    console.log("addrComps", addrComps);

    if (addrComps?.selAddr) {
      let nAddrComps = fFormatGglAddressComps(addrComps);
      console.log("nAddrComps", nAddrComps);

      if (nAddrComps.nProvince?.length) {
        const elAddr1 = document.getElementById("elAddr1");
        const elAddr2 = document.getElementById("elAddr2");
        const elCity = document.getElementById("elCity");
        const elProvince = document.getElementById("selProvince");

        const elZip = document.getElementById("elZip");
        const elTz = document.getElementById("selTz");

        const arrStOpts = [...elProvince?.options].map((s) => [
          s?.value,
          s?.getAttribute("data-scode"),
          s?.getAttribute("data-ptzid"),
        ]);
        let provData;

        if (elAddr1 && nAddrComps.nAddr1?.length) {
          elAddr1.value = nAddrComps.nAddr1;
          fSetFrmProfileVal("Address1", nAddrComps.nAddr1);
        }
        if (elAddr2) {
          elAddr2.value = nAddrComps.nAddr2;
          fSetFrmProfileVal("Address2", nAddrComps.nAddr2);
        }
        if (elCity) {
          elCity.value = nAddrComps.nCity;
          fSetFrmProfileVal("City", nAddrComps.nCity);
        }
        if (elProvince) {
          provData = arrStOpts.filter(
            (sts) => sts[1] === nAddrComps?.nProvinceCode?.toLowerCase()
          );
          provData = provData?.at(0);
          console.log("provData", provData);
          elProvince.value = provData?.at(0);
          fSetFrmProfileVal("Province", provData?.at(0));
          let stTzId = provData?.at(2);
          if (elTz && stTzId) {
            elTz.value = stTzId;
            fSetFrmProfileVal("LocalTimeZone", stTzId);
          }
        }
        if (elZip) {
          elZip.value = nAddrComps.nZip;
          fSetFrmProfileVal("ZIPCode", nAddrComps.nZip);
        }
      }
    }
  };
  const { ref: addrRef } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {
      try {
        const inEl = addrRef.current;
        inEl && place && fAutoPopulateAddress(inEl, place, aUserLanguageCode);
      } catch { }
    },
    options: {
      language: aUserLanguageCode,
      types: ["street_number", "street_address", "postal_code"],
      inputtype: "textquery",
      componentRestrictions: { country: defCountryCode },
    },
  });



  const fResetProfileForm = () => {
    fResetFrmProfile();
  };
  const fHandleProfilePicChange = async (fl) => {
    if (typeof fl === "object") {
      setStProcessing(true);
      const formData = new FormData();
      formData.append("profile_pic", fl);
      const profPicUpResp = await fCallNHApi("profPicUp", fSetUserProfile, {
        profileId: aUserProfileId,
        inParams: formData,
      });
      if (profPicUpResp?.result === true) {
        const nSFormData = new FormData();
        nSFormData.append("profile", fl);
        await fSetNSUser({
          userId: aUserNSId,
          inParams: nSFormData,
        });
        if (isMounted.current) {
          fRefetchProfile();
        }
        const uProfResp = await fGetUserProfDetails({
          profileId: aUserProfileId,
          userType: USER_TYPES.patient,
        });
        const newProfilePicUrl = uProfResp?.data?.data?.profile_pic_url;
        if (newProfilePicUrl) {
          fDispatch(rSetProfilePic({ profilePic: newProfilePicUrl }));
        }
      }
      setStProcessing(false);
    }
  };
  const fHandleProfileSubmit = async () => {
    let formData,
      profSaveResp,
      hasValidInputs = false;
    const inData = fGetFrmProfileVal();
    const checkValid = await fTriggerFrmProfile();
    hasValidInputs = checkValid && Object.keys(checkValid).length === 0;
    if (Object?.keys(localLang)?.length > 0) {
      localStorage.setItem('lang', JSON.stringify(localLang));
      i18n.changeLanguage(JSON.parse(localStorage.getItem('lang'))?.value)
    }
    if (hasValidInputs && inData) {
      profSaveResp = {
        rSaveUser: null,
        rSaveProfile: null,
        rSaveAddress: null,
      };
      setStProcessing(true);
      formData = new FormData();

      formData.append("phone", inData?.contactNum);
      profSaveResp.rSaveUser = await fCallNHApi("userUp", fSetUserData, {
        userId: aUserId,
        inParams: formData,
      });

      formData = new FormData();
      formData.append("date_of_birth", inData?.DateOfBirth);

      formData.append("timezone_id", inData?.LocalTimeZone);
      formData.append("language_id", localLangId);
      profSaveResp.rSaveProfile = await fCallNHApi(
        "userProfUp",
        fSetUserProfile,
        { profileId: aUserProfileId, inParams: formData }
      );

      formData = new FormData();

      formData.append("is_primary", 1);

      formData.append("address_line_1", inData?.Address1);
      formData.append("address_line_2", inData?.Address2 || "");
      formData.append("city", inData?.City);
      formData.append("state_id", inData?.Province);
      formData.append("zipcode", inData?.ZIPCode);
      formData.append("country_id", inData?.CountryID);
      formData.append("create_wallet", inData?.create_wallet);
      let fFx,
        fFxParams = {};
      if (aUserAddressId) {
        fFx = fSetUserAddr;
        fFxParams["addressId"] = aUserAddressId;
      } else {
        formData.append("addressable_type", "profile");
        formData.append("addressable_id", aUserProfileId);
        formData.append("profile_id", aUserProfileId);
        fFx = fAddUserAddr;
      }

      if (!profileApiData?.data?.user?.wallet) {
        try {
          await createWallet({
            user_id: aUserId,
          }).unwrap();
        } catch (err) {
          toast.error(err?.data?.message);
        }
      }

      fFxParams["inParams"] = formData;
      profSaveResp.rSaveAddress = await fCallNHApi(
        "userProfUp",
        fFx,
        fFxParams
      );

      if (
        profSaveResp?.rSaveUser?.result ||
        profSaveResp?.rSaveProfile.result ||
        profSaveResp?.rSaveAddress.result
      ) {
        if (isMounted.current) {
          fRefetchProfile();
        }
      }
      if (
        profSaveResp?.rSaveUser?.result &&
        profSaveResp?.rSaveProfile.result &&
        profSaveResp?.rSaveAddress.result
      ) {
        if (FLG_USE_SWAL) {
          toast.success(comp_profile("profile_update_successfull"));
        } else {
          setStPgMsg(comp_profile("profile_update_successfull"));
        }
      } else {
        if (FLG_USE_SWAL) {
          toast.error(Message);
          throw new Error(Message);
        } else {
          setStPgMsg(
            comp_profile("Error saving profile data. Please try again..")
          );
          setStPgHasErr(true);
        }
      }
    } else {
      setStPgHasErr(true);
      profSaveResp = false;
    }
    console.log("profSaveResp", profSaveResp);
    setTimeout(fResetProcessing, 3000);
    return profSaveResp;
  };
  //---------------------------------------------------------

  let pgMsg = null,
    aUsrProfilePicUrl = objProfData?.profile_pic_url || DEF_PROFILE_PIC_URL;

  const triggerToast = () => {
    if (stPgMsg) {
      if (stPgHasErr) {
        toast.error(stPgMsg);
      } else {
        toast.success(stPgMsg);
      }
    }
  };

  useEffect(() => {
    triggerToast();
  }, [stPgMsg, stPgHasErr]);

  const loaderJsx = (
    <div className="d-flex justify-content-center">
      <LottieSpinner size={200} />
    </div>
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fSetFrmProfileVal("DateOfBirth", date ? date.toISOString().split("T")[0] : null);
  };
  const range = (start, end, step) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };

  const Datepicker = () => {
    const years = range(1990, new Date().getFullYear() + 1, 1);

    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December",
    ];

    return (
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={(e) => {
              e.preventDefault();
              decreaseMonth();
            }} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>

            <select
              value={new Date(date).getFullYear()}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[new Date(date).getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={(e) => {
              e.preventDefault();
              increaseMonth();
            }} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
        className={`form-control ${Boolean(errFrmProfile.DateOfBirth) ? "is-invalid" : ""}`}
        placeholderText="MM/DD/YYYY"
      />
    );
  };
  return (
    <div id="ctnFrmProf">
      <form>
        <div className="row">
          <div className="col-md-2">
            <ProfilePicSetter
              imgSrc={aUsrProfilePicUrl}
              imgElId="imProfilePic"
              fileElId="iFPImg"
              fCallback={fHandleProfilePicChange}
            />
          </div>
          <div className="col">
            <div className="form-group row">
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label text-right"
              >
                {comp_profile("first_name")}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  readOnly
                  disabled
                  {...fRegFrmProfile("FirstName")}
                  className={`form-control ${Boolean(errFrmProfile.FirstName) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.FirstName && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.FirstName.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label text-right"
              >
                {comp_profile("last_name")}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  readOnly
                  disabled
                  {...fRegFrmProfile("LastName")}
                  className={`form-control ${Boolean(errFrmProfile.LastName) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.LastName && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.LastName.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="email-input" className="col-sm-2 col-form-label">
                {comp_profile("email")}
              </label>
              <div className="col-sm-10">
                <input
                  type="email"
                  readOnly
                  disabled={true}
                  className="form-control"
                  name="email"
                  defaultValue={objProfData?.user?.email}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-tel-input"
                className="col-sm-2 col-form-label required"
              >
                {comp_profile("contact_info")}
              </label>
              <div className="col-sm-10">
                <PhoneInput
                  required
                  className={`form-control ${Boolean(errFrmProfile.contactNum) ? "is-invalid" : ""
                    }`}
                  id="contactNum"
                  name="contactNum"
                  placeholder="(xxx) xxx-xxxx"
                  value={objProfData?.user?.phone || ""}
                  defaultCountry={defCountryCode}
                  international={false}
                  onChange={(val) => {
                    if (val && typeof val === "string") {
                      fSetFrmProfileVal("contactNum", val);
                      clearErrors("contactNum");
                    }
                  }}
                  onBlur={(e) => {
                    const val = e.target.value;
                    if (val && typeof val === "string") {
                      clearErrors("contactNum");
                    } else {
                      setError("contactNum", {
                        type: "manual",
                        message: error_message("Invalid phone number"),
                      });
                    }
                  }}
                />
                {errFrmProfile.contactNum && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.contactNum.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label required"
              >
                {comp_profile("address_line_1")}
              </label>
              <div className="col-sm-10">
                <input
                  ref={addrRef}
                  type="text"
                  // {...fRegFrmProfile("Address1")}
                  name="Address1"
                  id="elAddr1"
                  defaultValue={aUserAddressData?.address_line_1 || ""}
                  className={`form-control ${Boolean(errFrmProfile.Address1) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.Address1 && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.Address1.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label"
              >
                {comp_profile("address_line_2")}
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  {...fRegFrmProfile("Address2")}
                  name="Address2"
                  id="elAddr2"
                  defaultValue={aUserAddressData?.address_line_2 || ""}
                  className={`form-control ${Boolean(errFrmProfile.Address2) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.Address2 && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.Address2.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label required">
                {comp_profile("city")}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  {...fRegFrmProfile("City")}
                  id="elCity"
                  className={`form-control ${Boolean(errFrmProfile.City) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.City && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.City.message)}
                  </label>
                )}
              </div>
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label required"
              >
                {comp_profile("state")}
              </label>
              <div className="col-sm-4">
                {arrStates.length > 0 ? (
                  <select
                    {...fRegFrmProfile("Province")}
                    id="selProvince"
                    className={`form-control ${Boolean(errFrmProfile.Province) ? "is-invalid" : ""
                      }`}
                  >
                    <option value={""} data-scode={""} data-ptzid="">
                      Select
                    </option>
                    {arrStates.map((province, index) => (
                      <option
                        value={province.id}
                        key={index}
                        data-scode={province?.code.toLowerCase()}
                        data-ptzid={province?.primary_timezone_id}
                      >
                        {province.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    {...fRegFrmProfile("Province")}
                    id="selProvince"
                    className={`form-control ${Boolean(errFrmProfile.Province) ? "is-invalid" : ""
                      }`}
                  />
                )}
                {errFrmProfile.Province && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.Province.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label required"
              >
                {comp_profile("zip_code")}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  {...fRegFrmProfile("ZIPCode")}
                  id="elZip"
                  className={`form-control ${Boolean(errFrmProfile.ZIPCode) ? "is-invalid" : ""
                    }`}
                />
                {errFrmProfile.ZIPCode && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.ZIPCode.message)}
                  </label>
                )}
              </div>
              <label className="col-sm-2 col-form-label ">
                {comp_profile("country")}
              </label>
              <div className="col-sm-4">
                <input
                  disabled
                  readOnly
                  type="text"
                  defaultValue={defCountryName}
                  className={`form-control ${Boolean(errFrmProfile.CountryID) ? "is-invalid" : ""
                    }`}
                />
                <input
                  type="hidden"
                  id="country_id"
                  {...fRegFrmProfile("CountryID")}
                  value={defCountryId}
                />
                {errFrmProfile.CountryID && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.CountryID.message)}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="example-date-input"
                className="col-sm-2 col-form-label text-right"
              >
                {comp_profile("local_timezone")}
              </label>
              <div className="col-sm-4">
                {flgUseTimezoneScheme2 ? (
                  <TimeZoneSelect2
                    ddId="selTZ"
                    required={true}
                    regFx={fRegFrmProfile}
                    elName="LocalTimeZone"
                    tzOptions={arrTz}
                    filterCountryCode={defCountryCode}
                    elStyle={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      maxWidth: 400,
                    }}
                    className={`form-control ${Boolean(errFrmProfile.LocalTimeZone) ? "is-invalid" : ""
                      }`}
                  />
                ) : (
                  <></>
                )}
                {errFrmProfile.LocalTimeZone && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.LocalTimeZone.message)}
                  </label>
                )}
              </div>
              <label
                htmlFor="example-date-input"
                className="col-sm-2 col-form-label text-right required"
              >
                {comp_profile("date_of_birth")}
              </label>
              <div className="col-sm-4">
                <Datepicker />
                {errFrmProfile.DateOfBirth && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.DateOfBirth.message)}
                  </label>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="example-text-input"
                className="col-sm-2 col-form-label"
              >
                {comp_profile("language")}
              </label>
              <div className="col-sm-4">
                <LanguageDropdown
                  arrLanguage={arrLangs}
                  onChange={fHandleLanguageChange}
                  value={i18n.language}
                  error={Boolean(errFrmProfile.Language)}
                  defaultLocalLang={JSON.parse(localStorage.getItem('lang')) || defaultLocalLang}
                />
                {errFrmProfile.Language && (
                  <label className="text-danger">
                    {error_message(errFrmProfile.Language.message)}
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="padS dFlexC xtBdr2">
          <div>&nbsp;</div>
          <div className="xtBdr2">
            <div className="xdFlexC xtBdr2">{pgMsg}</div>
          </div>
          <div className="txAC xtBdr2">
            <button
              type="button"
              disabled={stProcessing}
              className={"btn btn-success btn-round waves-effect waves-light"}
              style={{ marginRight: "10px" }}
              onClick={fHandleProfileSubmit}
            >
              {comp_profile("submit_button")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

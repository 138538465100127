import React, { useEffect, useState } from 'react';
import { fRedirectToNiuSocial } from 'v2/src/lib/appUtils';

const CardComponent = ({ arg }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getImageHeight = () => {
    if (windowWidth <= 576) {
      return 200;
    } else if (windowWidth < 768) {
      return 310;
    } else if (windowWidth <= 1000) {
      return 180;
    } else if (windowWidth <= 1200) {
      return 280;
    } else {
      return 352;
    }
  };

  return (
    <div className="card text-center" style={{ height: "100%" }}>
      <img
        className="card-img-top px-2 pt-2"
        id="wallet-card-media"
        src={arg.img}
        alt="Card image cap"
        style={{
          width: '100%',
          height: getImageHeight(),
          objectFit: 'cover',
        }}
      />
      <div className="card-body" style={{ paddingBottom: "10px" }}>
        <div className="d-flex flex-column">
          <span className="text-muted" style={{ fontSize: '22px' }}>
            {arg.mutedText}
          </span>
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {arg.boldText}
          </span>
        </div>
      </div>
      <div class="card-footer bg-transparent" style={{ paddingBottom: "2rem" }}>
        <button
          onClick={() => { fRedirectToNiuSocial() }}
          className="btn btn-success rounded-pill"
          style={{ width: "100%", maxWidth: "200px" }}
        >
          {arg.buttonText}
        </button>
      </div>
    </div>
  );
};

export default CardComponent

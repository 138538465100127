import { nHApi } from 'v2/src/lib/services/public/nHApi';

export const nHWalletApi = nHApi.injectEndpoints({
  endpoints: builder => ({
    getWalletTransactionList: builder.query({
      query: ({ user_id, start_date, end_date, per_page, page, sort_order }) => {
        const params = new URLSearchParams();
        params.append('user_id', user_id);
        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);
        if (sort_order) params.append('sort_order', sort_order);
        if (per_page) params.append('per_page', per_page);
        if (page) params.append('page', page);
        return {
          url: `/transactions?${params.toString()}`,
        };
      },
    }),
    createWallet: builder.mutation({
      query: (apiParams) => {
        return {
          url: `/wallet/create`,
          method: 'POST',
          body: apiParams,
        }
      },
    }),
  }),
});

export const {
  useLazyGetWalletTransactionListQuery,
  useCreateWalletMutation
} = nHWalletApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NS_API_BASE_URL, } from 'v2/src/config/appConfig';
//import { rSetUserCredentials, rSetLogOut } from './auth/authSlice';
import Cookies from "js-cookie";
//---------------------------------------------------------

const baseQuery = fetchBaseQuery({
	baseUrl: NS_API_BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		headers.set('Connection', 'keep-alive');
		headers.set('Accept', 'application/json');
		let lAT, authToken;
		authToken = lAT ?? getState()?.auth?.authTokenNS;
		authToken = authToken ?? Cookies.get('accessTokenNS');
		//console.log('trtrtrt', authToken);
		if (authToken) {//console.log('authTokenNS', authToken);
			//headers.set('Authorization', `Bearer ${authToken}`);
			headers.set('Authorization', 'Bearer ' + authToken);
		}
		return headers;
	},
	//timeout: 10000, //jsonContentType: true,
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
	let resp = await baseQuery(args, api, extraOptions);
	console.log('nSBaseQWithReA', { args, api, extraOptions, resp });

	/*if(resp?.error?.originalStatus === 403) {
		const refRes = await baseQuery('/refresh', api, extraOptions);
		if(refRes?.data) {
			const user = api.getState().auth.user;
			api.dispatch(rSetUserCredentials({...refRes?.data, user}));
			resp = await baseQuery(args, api, extraOptions);
		} else { api.dispatch(rSetLogOut()); }
	} else*/ if (!resp.data && !resp.error) {
		return { data: null, meta: resp.meta, };
	} else if (resp.data) {
		//resp.data = resp.data?.data;
		//resp = resp.data;
		//if(resp.data?.status === true) {}
	} else if (resp.error) {
		//resp.error = resp.error?.data;
	}//console.log('finalResp', resp);
	return resp;
};

//export const sSApiBaseQuery = baseQueryWithReAuth;
export const nSApi = createApi({
	//reducerPath: 'api',
	baseQuery: baseQueryWithReAuth,
	endpoints: builder => ({}),
	//endpoints: () => ({}),
	//tagTypes: ['user', ],
	//overrideExisting: false,
});

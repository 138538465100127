import { useTranslation } from "react-i18next";
import CardComponent from "./card";

const NIUTopia = () => {
  const { t: wallet } = useTranslation("wallet")

  const niuTopiaData = [
    {
      img: '/assets/images/niutopia-1.png',
      mutedText: wallet("start_by_connecting"),
      boldText: wallet("buy_niutopia"),
      buttonText: wallet("connect")
    },
    {
      img: '/assets/images/niutopia-2.png',
      mutedText: wallet("start_by_creating"),
      boldText: wallet("membership"),
      buttonText: wallet("share")
    }, {
      img: '/assets/images/niutopia-3.png',
      mutedText: wallet("start_by_sharing"),
      boldText: wallet("partner_niutopia"),
      buttonText: wallet("share")
    }
  ]

  return (
    <div className="bg-white pt-3 ">
      <h5>{wallet("ways_to_integrate_niutopia")}</h5>
      <div className="row ">
        {niuTopiaData.map((item, index) => (
          <div className="col-md-4" key={index}><CardComponent arg={item} /></div>
        ))}
      </div>
    </div>
  )
}

export default NIUTopia;
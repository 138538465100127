import { useEffect, useState } from "react";
import CustomTable from "./CustonTable";
import { useTranslation } from "react-i18next";
import { useLazyGetWalletTransactionListQuery } from "v2/src/lib/services/nHWalletApi";
import { fGetAUserInf } from "v2/src/lib/appUtils";
import moment from "moment";
import CoinSVG from "./CoinSVG";

const Transactions = () => {

  const { t: wallet } = useTranslation('wallet');
  let aUData = fGetAUserInf();

  const aUserId = aUData?.userIdNH;

  const [getWalletTransactionList, { data: walletTransactionData }] =
    useLazyGetWalletTransactionListQuery();

  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1)
  const [rowPerPage, setRowPerPage] = useState(0)
  const [total, setTotal] = useState(0)

  const transactionColumn = [
    { id: "id", label: wallet('id') },
    { id: "created_at", label: wallet('date'), render: (elm) => moment(elm?.created_at).format("MM/DD/YYYY") },
    { id: "name", label: wallet('transaction_name'), render: (elm) => elm?.point_transaction?.transaction_name || "-" },
    { id: "counter_party", label: wallet('counter_party'), render: (elm) => elm?.point_transaction?.counter_party || "-" },
    { id: "type", label: wallet('type'), render: (elm) => elm?.type?.charAt(0).toUpperCase() + elm?.type.slice(1) },
    { id: "coin_amount", label: wallet('coins'), render: (elm) => (<>{elm?.type === "credit" ? "+" : elm?.type === "debit" ? "-" : ""} <CoinSVG /> {parseFloat(elm?.coin_amount).toFixed(4)}</>) },
  ];

  useEffect(() => {
    const params = {
      user_id: aUserId,
      per_page: 5,
      page,
      sort_order: "desc"
    };
    getWalletTransactionList(params);
  }, [aUserId, page])


  useEffect(() => {
    if (walletTransactionData?.data) {
      setTransactions(walletTransactionData?.data);
      setPage(walletTransactionData?.meta?.current_page)
      setRowPerPage(walletTransactionData?.meta?.per_page)
      setTotal(walletTransactionData?.meta?.total)
    }
  }, [walletTransactionData])

  return (
    <div className="bg-white pt-3">
      <CustomTable
        columns={transactionColumn}
        rows={transactions}
        total={total}
        perPage={rowPerPage}
        page={page}
        setPage={(number) => setPage(number)}
      />
    </div>
  );
};

export default Transactions;

import i18n from './i18n' // Import the i18n instance

export const APP_ENV = process.env.REACT_APP_APP_ENV
export const APP_URL = process.env.PUBLIC_URL;//Useless as it is Blank
export const APP_BASE_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL
export const API_URL_V2 = process.env.REACT_APP_API_URL_V2;
export const AWS_COGNITO_HOSTUI_DOMAIN = process.env.REACT_APP_AWS_COGNITO_HOSTUI_DOMAIN
export const APP_LOGIN = process.env.REACT_APP_LOGIN
export const TRANSACTIONTYPE = {
  login: 'Login',
  register: 'Register',
}
export const USERTYPE = {
  provider: 'provider',
  patient: 'patient',
}
export const DEF_COUNTRY_ID = 232;//USA
export const DEF_COUNTRY_CODE = 'US';
export const DEF_COUNTRY_NAME = 'United States';
export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET
export const AWS_BUCKET_SERVICES = process.env.REACT_APP_AWS_BUCKET_SERVICES
export const AWS_BUCKET_PROFILES = process.env.REACT_APP_AWS_BUCKET_PROFILES
export const AWS_BUCKET_DOCUMENTS = process.env.REACT_APP_AWS_BUCKET_DOCUMENTS
export const ZOOM_SDK = process.env.REACT_APP_ZOOM_SDK
export const NIU_SOCIAL_FE_URL = process.env.REACT_APP_NIU_SOCIAL_FE_URL
export const ENABLE_MY_SOCIAL = process.env.REACT_APP_ENABLE_MY_SOCIAL
export const DR_COCO_AI_URL = process.env.REACT_APP_DR_COCO_AI_URL
export const NIU_SA_API_URL = process.env.REACT_APP_NIU_SA_API_URL
export const SA_DEF_ORG_ID = process.env.REACT_APP_SA_DEF_ORG_ID
export const SA_DEF_ORG_GROUP_ID = process.env.REACT_APP_SA_DEF_ORG_GROUP_ID
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const VERIFF_API_KEY = process.env.REACT_APP_VERIFF_API_KEY

export const INSURANCE_STATUS_LIST = ['not_uploaded', 'pending_validation', 'approved', 'rejected', 'expired']
export const SUBSCRIPTION_STATUS_LIST = ['not_subscribed', 'subscribed', 'cancelled', 'expired']
export const INSURANCE_PENDING = 0
export const INSURANCE_APPROVED = 1
export const INSURANCE_REJECTED = 2

export const FLG_USE_SWAL = false;

/* Error messages */
export const requiredError = 'This field is required'
export const nameMinLengthError = 'Must be at least 2 characters'
export const nameMaxLengthError = 'Cannot be longer than 50 characters'
export const nameFormatError = 'Must only contain letters'
export const emailError = 'Please enter a valid email address'
export const emailMaxLengthError = 'Email cannot be longer than 255 characters'
export const emailMinLengthError = 'Email must be at least 6 characters'
export const passwordMinLengthError = 'Must be at least 8 characters'
export const passwordMaxLengthError = 'Cannot be longer than 50 characters'
export const passwordFormatError = 'Must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
export const confirmPasswordError = 'Passwords must match'
export const termsError = 'You must agree to the Terms of Use.'
export const mobileError = 'Please enter a valid phone number'
export const addressMaxLengthError = 'Address cannot be longer than 255 characters'
export const cardFullNameError = 'Card full name must contain only letters and spaces'
export const cvvLengthError = 'CVC must be a valid 3 or 4-digit number'
export const zipcodeError = 'Zipcode should be 5 digits'
export const noSpecialCharactersError = 'Special characters are not allowed'
export const maxCharactersError = 'This field cannot exceed 50 characters'
export const noFutureDOB = 'Date of Birth cannot be in the future'
export const min11YearsOld = 'You must be at least 11 years old'

import {
  ContainerFluid,
  TableTitle,
} from "components/table/Tables";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NuiCoins from "./NuiCoins";
import Transactions from "./Transactions";
import NIUTopia from "./NIUTopia";
import { fGetAUserInf } from "v2/src/lib/appUtils";
import { useUserProfileDetailQuery } from "v2/src/lib/services/profileApi";
import { useCreateWalletMutation } from "v2/src/lib/services/nHWalletApi";
import { toast } from "react-toastify";

const Wallet = () => {
  const [activeTab, setActiveTab] = useState("nui-coins");
  const { t: wallet } = useTranslation("wallet");
  const { t: common } = useTranslation("common");
  let aUData = fGetAUserInf();
  const navigate = useNavigate();
  const [createWallet] = useCreateWalletMutation();
  const isMounted = useRef(true);

  const aUserProfileId = aUData?.details?.profileId;
  const arrComps = ["user", "role", "language", "addresses", "patient"];

  const { data: profileApiData, isLoading, refetch: fRefetchProfile, } = useUserProfileDetailQuery({ profileId: aUserProfileId, arrComps }, { skip: aUserProfileId === null, refetchOnMountOrArgChange: true });

  const walletData = profileApiData?.data?.user?.wallet

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCreateWallet = async () => {
    try {
      await createWallet({
        user_id: profileApiData?.data?.user?.id,
      }).unwrap();
      if (isMounted.current) {
        fRefetchProfile();
      }
    } catch (err) {
      toast.error(err?.data?.message);
    }
  }

  useEffect(() => {
    if (!isLoading && !walletData) {
      Swal.fire({
        html: wallet("no_wallet_found"),
        showConfirmButton: true,
        confirmButtonText: common("create_wallet"),
        showCancelButton: true,
        cancelButtonText: `${common("cancel")}`,
      }).then(async ({ value, dismiss }) => {
        if (value) {
          handleCreateWallet()
        } else {
          navigate("/patient/profile", { replace: true });
        }
      });
    }
  }, [])

  return (
    <>
      <div
        className="page-wrapper-custom"
        style={{ marginTop: "4rem", paddingBottom: "8px" }}
      >
        <ContainerFluid>
          <TableTitle title={wallet("wallet")} />
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mt-0 mb-3">
                    {wallet("view")}
                  </h4>
                  <div className="app-tabs">
                    <ul className="tabs">
                      <li
                        className={`tab ${activeTab === "nui-coins" ? "active" : ""}`}
                        onClick={() => handleTabClick("nui-coins")}
                      >
                        {wallet("niu_coins")}
                      </li>
                      <li
                        className={`tab ${activeTab === "niutopia" ? "active" : ""}`}
                        onClick={() => handleTabClick("niutopia")}
                      >
                        {wallet("niutopia")}
                      </li>
                      <li
                        className={`tab ${activeTab === "transactions" ? "active" : ""}`}
                        onClick={() => handleTabClick("transactions")}
                      >
                        {wallet("transactions")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
      </div>
      <div className="page-wrapper-custom">
        <ContainerFluid>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="tab-content">
                    {activeTab === "nui-coins" ? (
                      <div className="tab-section">
                        <NuiCoins />
                      </div>
                    ) : activeTab === "niutopia" ? (
                      <div className="tab-section">
                        <NIUTopia />
                      </div>
                    ) : activeTab === "transactions" ? (
                      <div className="tab-section">
                        <Transactions />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerFluid>
      </div>
    </>
  )
}

export default Wallet